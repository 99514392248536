<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <CreateEditCartaModal
      v-if="showCreateEditCartaModal"
      :cartaId="cartaIdSelected"
      @closeModal="closeCreateEditCartaModal"
    />
    <LoadingData v-if="loadData" />
    <div v-else class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
      >
        <h5 class="card-title mb-0">Cartas contempladas</h5>
        <div v-if="getModuleUserByCode('2.01.002')">
          <button
            type="button"
            class="btn btn-primary"
            title="Nova Carta"
            @click="createEditCarta()"
          >
            Nova Carta
          </button>
        </div>
      </div>
      <div class="card-body">
        <ListCartas :cartas="cartas" @editCarta="createEditCarta" />
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import ListCartas from '@/components/backoffice/cartas/ListCartas.vue'
import CreateEditCartaModal from '@/components/backoffice/cartas/CreateEditCartaModal.vue'

export default {
  name: 'Cartas',

  components: {
    Layout,
    PageHeader,
    CreateEditCartaModal,
    LoadingData,
    ListCartas,
  },

  data() {
    return {
      title: 'Cartas Contempladas',
      items: [
        {
          text: 'Cartas Contempladas',
          active: true,
        },
      ],
      loadData: true,
      showCreateEditCartaModal: false,
      cartas: null,
      cartaIdSelected: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getCartas() {
      try {
        this.cartas = await this.$store.dispatch('getCartas')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    createEditCarta(cartaId) {
      this.cartaIdSelected = cartaId || null
      this.showCreateEditCartaModal = true
    },

    closeCreateEditCartaModal(loadListCartas) {
      if (loadListCartas) {
        this.getCartas()
      }
      this.showCreateEditCartaModal = false
    },
  },

  mounted() {
    this.getCartas()
  },
}
</script>
