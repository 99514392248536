<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <CreateEditSegmentoModal
      v-if="showCreateEditSegmentoModal"
      :segmentoId="segmentoIdSelected"
      @closeModal="closeCreateEditSegmentoModal"
    />
    <LoadingData v-if="loadData" />
    <div v-else class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
      >
        <h5 class="card-title mb-0">Segmentos</h5>
        <div v-if="getModuleUserByCode('4.01.002')">
          <button
            type="button"
            class="btn btn-primary"
            title="Nova Segmento"
            @click="createEditSegmento()"
          >
            Nova Segmento
          </button>
        </div>
      </div>
      <div class="card-body">
        <ListSegmentos
          :segmentos="segmentos"
          @editSegmento="createEditSegmento"
        />
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import ListSegmentos from '@/components/backoffice/cartas/segmentos/ListSegmentos.vue'
import CreateEditSegmentoModal from '@/components/backoffice/cartas/segmentos/CreateEditSegmentoModal.vue'

export default {
  name: 'Segmentos',

  components: {
    Layout,
    PageHeader,
    CreateEditSegmentoModal,
    LoadingData,
    ListSegmentos,
  },

  data() {
    return {
      title: 'Segmentos',
      items: [
        {
          text: 'Segmentos',
          active: true,
        },
      ],
      loadData: true,
      showCreateEditSegmentoModal: false,
      segmentos: null,
      segmentoIdSelected: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getSegmentos() {
      try {
        this.segmentos = await this.$store.dispatch('getSegmentos')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    createEditSegmento(segmentoId) {
      this.segmentoIdSelected = segmentoId || null
      this.showCreateEditSegmentoModal = true
    },

    closeCreateEditSegmentoModal(loadListSegmentos) {
      if (loadListSegmentos) {
        this.getSegmentos()
      }
      this.showCreateEditSegmentoModal = false
    },
  },

  mounted() {
    this.getSegmentos()
  },
}
</script>
