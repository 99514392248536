<template>
  <div>
    <CreateEditProjectModal
      v-if="showCreateEditProjectModal"
      :clientId="clientId"
      :projectId="projectIdSelected"
      @closeModal="closeCreateEditProjectModal"
    />
    <DetailsProjectModal
      v-if="showDetailsProjectModal"
      :clientId="clientId"
      :projectId="projectIdSelected"
      @closeModal="closeDetailsProjectModal"
    />
    <div v-if="!loadData" class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
      >
        <h5 class="card-title mb-0">Projetos</h5>
        <div v-if="getModuleUserByCode('1.01.002')">
          <button
            type="button"
            class="btn btn-primary"
            title="Novo Projeto"
            @click="createEditProject()"
          >
            Novo Projeto
          </button>
        </div>
      </div>
      <div class="card-body">
        <ListProjects
          :projects="projects"
          @editProject="createEditProject"
          @detailsProject="detailsProject"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CreateEditProjectModal from '@/components/backoffice/projects/CreateEditProjectModal.vue'
import DetailsProjectModal from '@/components/backoffice/projects/DetailsProjectModal.vue'
import ListProjects from '@/components/backoffice/projects/ListProjects.vue'

export default {
  name: 'ProjectsClient',

  components: {
    CreateEditProjectModal,
    DetailsProjectModal,
    ListProjects,
  },

  props: {
    clientId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loadData: true,
      showCreateEditProjectModal: false,
      showDetailsProjectModal: false,
      projects: null,
      projectIdSelected: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getProjects() {
      try {
        this.projects = await this.$store.dispatch('getProjects', {
          clientId: this.clientId,
        })
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    createEditProject(projectId) {
      this.projectIdSelected = projectId || null
      this.showCreateEditProjectModal = true
    },

    closeCreateEditProjectModal(loadListProjects) {
      if (loadListProjects) {
        this.getProjects()
      }
      this.showCreateEditProjectModal = false
    },

    detailsProject(projectId) {
      this.projectIdSelected = projectId
      this.showDetailsProjectModal = true
    },

    closeDetailsProjectModal() {
      this.showDetailsProjectModal = false
    },
  },

  mounted() {
    this.getProjects()
  },
}
</script>
