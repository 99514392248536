<template>
  <b-modal
    id="modalCreateEditCarta"
    size="xl"
    :title="textModal"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <form @submit.prevent="save">
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Código: <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.carta.codigo.$error }"
            placeholder="Código da carta comtemplada"
            v-model="$v.carta.codigo.$model"
          />
          <span v-if="!$v.carta.codigo.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-6">
          <label>Administradora: <span class="text-danger">*</span></label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.carta.administradora_id.$error }"
            v-model="$v.carta.administradora_id.$model"
          >
            <option value="">Selecione a administradora</option>
            <option
              v-for="administradora in administradoras"
              :key="administradora.id"
              :value="administradora.id"
              :title="`${administradora.descricao}`"
            >
              {{ administradora.descricao }}
            </option>
          </select>
          <span
            v-if="!$v.carta.administradora_id.required"
            class="invalid-feedback"
          >
            O campo é obrigatório
          </span>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Segmento: <span class="text-danger">*</span></label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.carta.segmento_id.$error }"
            v-model="$v.carta.segmento_id.$model"
          >
            <option value="">Selecione um segmento</option>
            <option
              v-for="segmento in segmentos"
              :key="segmento.id"
              :value="segmento.id"
              :title="`${segmento.descricao}`"
            >
              {{ segmento.descricao }}
            </option>
          </select>
          <span v-if="!$v.carta.segmento_id.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-6">
          <label>Valor do crédito: <span class="text-danger">*</span></label>
          <div class="input-group">
            <span
              class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
            >
              <span class="input-group-text">R$</span></span
            >
            <input
              type="number"
              class="form-control"
              step="0.01"
              :class="{ 'is-invalid': $v.carta.valor_credito.$error }"
              v-model.number="$v.carta.valor_credito.$model"
              placeholder="000,00"
              @blur="formatarValorCredito"
            />
          </div>
          <span
            v-if="!$v.carta.valor_credito.required"
            class="invalid-feedback"
          >
            O campo é obrigatório
          </span>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Valor da entrada: <span class="text-danger">*</span></label>
          <div class="input-group">
            <span
              class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
            >
              <span class="input-group-text">R$</span></span
            >
            <input
              type="number"
              class="form-control"
              step="0.01"
              :class="{ 'is-invalid': $v.carta.valor_entrada.$error }"
              v-model.number="$v.carta.valor_entrada.$model"
              placeholder="000,00"
              @blur="formatarValorEntrada"
            />
          </div>
          <span
            v-if="!$v.carta.valor_entrada.required"
            class="invalid-feedback"
          >
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-6">
          <label>Data do vencimento: <span class="text-danger">*</span></label>
          <input
            type="date"
            class="form-control"
            :class="{ 'is-invalid': $v.carta.vencimento.$error }"
            placeholder="Data do vencimento"
            v-model="$v.carta.vencimento.$model"
          />
          <span v-if="!$v.carta.vencimento.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
      </div>
      <hr />
      <div class="form-row">
        <button
          type="button"
          @click="addParcela()"
          class="btn btn-primary"
          title="Adicionar parcelas"
        >
          <i class="bx ri-add-line"></i> Adicionar Parcelas
        </button>
      </div>
      <br />
      <div class="form-row" v-for="(parcela, index) in parcelas" :key="index">
        <div class="form-group col-sm-3">
          <label>Nº Parcelas: <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            placeholder="Qtd."
            :name="'parcelas[' + index + '].[parcelas]'"
            :id="'parcelas[' + index + ']'"
            v-model="parcelas[index].parcelas"
          />
        </div>
        <div class="form-group col-sm-7">
          <label>Valor das parcelas: <span class="text-danger">*</span></label>
          <div class="input-group">
            <span
              class="input-group-addon bootstrap-touchspin-prefix input-group-prepend"
            >
              <span class="input-group-text">R$</span></span
            >
            <input
              type="number"
              class="form-control"
              step="0.01"
              :name="'parcelas[' + index + '].[valor]'"
              :id="'parcelas[' + index + ']'"
              v-model="parcelas[index].valor"
              placeholder="000,00"
            />
          </div>
        </div>
        <div class="form-group col-sm-1">
          <br />
          <button
            type="button"
            @click="deleteParcela(index)"
            class="btn btn-lg btn-outline-danger"
            title="Adicionar item"
          >
            <i class="bx ri-delete-bin-line"></i>
          </button>
        </div>
      </div>
      <div v-if="cartaId" class="form-row">
        <div class="form-group col-sm-6">
          <label>Status: <span class="text-danger">*</span></label>
          <select class="form-control" v-model="carta.status">
            <option value="1">Disponível</option>
            <option value="2">Reservada</option>
            <option value="3">Vendida</option>
          </select>
        </div>
        <div
          v-if="carta.status == 2 || carta.status == 3"
          class="form-group col-sm-6"
        >
          <label>Cliente: <span class="text-danger">*</span></label>
          <select class="form-control" v-model="carta.client_id">
            <option value="">Selecione um cliente</option>
            <option
              v-for="client in clients"
              :key="client.id"
              :value="client.id"
              :title="`${client.name}`"
            >
              {{ client.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-6">
          <p>Disponível online: <span class="text-danger">*</span></p>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              id="disponivelNao"
              value="0"
              v-model="carta.disponivel_online"
            />
            <label class="form-check-label" for="disponivelNao"> Não </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              id="disponivelSim"
              value="1"
              v-model="carta.disponivel_online"
            />
            <label class="form-check-label" for="disponivelSim"> Sim </label>
          </div>
        </div>
      </div>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="button"
          class="btn btn-secondary"
          title="Cancelar"
          @click="$emit('closeModal', false)"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          :title="textModal"
          :disabled="$v.$invalid || disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>{{ textModal }}</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CreateEditCartaModal',

  props: {
    cartaId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      carta: {
        codigo: '',
        administradora_id: '',
        segmento_id: '',
        valor_credito: '',
        valor_entrada: '',
        vencimento: '',
        disponivel_online: 1,
      },
      disableButton: false,
      administradoras: null,
      segmentos: null,
      clients: null,
      parcelas: [],
      loadDataEditing: false,
    }
  },

  computed: {
    textModal() {
      return this.cartaId ? 'Atualizar' : 'Cadastrar'
    },
  },

  methods: {
    async formatarValorCredito() {
      const novoValor = this.carta.valor_credito.toFixed(2)
      this.carta.valor_credito = novoValor
      this.parcelamento()
    },

    async formatarValorEntrada() {
      const novoValor = this.carta.valor_entrada.toFixed(2)
      this.carta.valor_entrada = novoValor
      this.parcelamento()
    },

    addParcela() {
      this.parcelas.push({
        parcelas: '',
        valor: '',
      })
    },

    deleteParcela(index) {
      this.parcelas.splice(index, 1)
    },

    async getAdministradoras() {
      try {
        this.administradoras = await this.$store.dispatch('getAdministradoras')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },
    async getClients() {
      try {
        this.clients = await this.$store.dispatch('getClients')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getSegmentos() {
      try {
        this.segmentos = await this.$store.dispatch('getSegmentos')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getCarta() {
      try {
        this.loadDataEditing = true
        this.carta = await this.$store.dispatch('getCarta', this.cartaId)
        this.parcelas = await this.$store.dispatch(
          'getParcelasByCarta',
          this.cartaId,
        )
        this.$bvModal.show('modalCreateEditCarta')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (this.cartaId) {
            if (this.carta.status === '1') {
              console.log(this.carta.status)
              this.carta.client_id = null
              console.log(this.carta)
            }
            response = await this.$store.dispatch('updateCarta', {
              id: this.cartaId,
              carta: this.carta,
              parcelas: this.parcelas,
            })
          } else {
            response = await this.$store.dispatch('saveCarta', {
              carta: this.carta,
              parcelas: this.parcelas,
            })
          }
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
          })
          this.$emit('closeModal', true)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    carta: {
      codigo: {
        required,
      },
      valor_credito: {
        required,
      },
      valor_entrada: {
        required,
      },
      administradora_id: {
        required,
      },
      segmento_id: {
        required,
      },
      vencimento: {
        required,
      },
    },
  },

  mounted() {
    this.getAdministradoras()
    this.getSegmentos()
    this.addParcela()
    this.getClients()
    if (this.cartaId) {
      this.getCarta()
    } else {
      this.$bvModal.show('modalCreateEditCarta')
    }
  },
}
</script>
