<template>
  <b-modal
    id="modalCreateEditClient"
    :title="textModal"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <form @submit.prevent="save">
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Nome: <span class="text-danger">*</span></label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.client.name.$error }"
            placeholder="Nome Completo"
            v-model="$v.client.name.$model"
          />
          <span v-if="!$v.client.name.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
          <span v-if="!$v.client.name.minLength" class="invalid-feedback">
            O nome deve possuir pelo menos 8 caracteres
          </span>
        </div>
        <div class="form-group col-sm-6">
          <label>CNPJ/CPF: <span class="text-danger">*</span></label>
          <the-mask
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.client.cpfcnpj.$error }"
            placeholder="CNPJ ou CPF"
            v-model="$v.client.cpfcnpj.$model"
            :mask="['###.###.###-##', '##.###.###/####-##']"
            :masked="true"
          />
          <span v-if="!$v.client.cpfcnpj.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
          <span v-if="!$v.client.cpfcnpj.minLength" class="invalid-feedback">
            Formato inválido <br />
            Ex: 00.000.000/0000-00 / 000.000.000-00
          </span>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Telefone: <span class="text-danger">*</span></label>
          <the-mask
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.client.phone.$error }"
            placeholder="(00) 98888-8888"
            v-model="$v.client.phone.$model"
            :mask="['(##) ####-####', '(##) #####-####']"
            :masked="true"
          />
          <span v-if="!$v.client.phone.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
          <span v-if="!$v.client.phone.minLength" class="invalid-feedback">
            Formato de telefone inválido <br />
            Ex: (00) 98888-8888 / (00) 8888-8888
          </span>
        </div>
        <div class="form-group col-sm-6">
          <label>E-mail: </label>
          <input
            type="email"
            class="form-control"
            :class="{ 'is-invalid': $v.client.email.$error }"
            v-model="$v.client.email.$model"
            placeholder="usuario@email.com.br"
          />
          <span v-if="!$v.client.email.email" class="invalid-feedback">
            Formato de e-mail inválido
          </span>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-6">
          <label>Estado: <span class="text-danger">*</span></label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.client.stateId.$error }"
            v-model="$v.client.stateId.$model"
          >
            <option value="">Selecione o estado</option>
            <option
              v-for="state in states"
              :key="state.id"
              :value="state.id"
              :title="`${state.uf} - ${state.name}`"
            >
              {{ state.uf }}
            </option>
          </select>
          <span v-if="!$v.client.stateId.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
        <div class="form-group col-sm-6">
          <label>Cidade: <span class="text-danger">*</span></label>
          <select
            class="form-control"
            :class="{ 'is-invalid': $v.client.cityId.$error }"
            v-model="$v.client.cityId.$model"
          >
            <option value="">Selecione uma cidade</option>
            <option
              v-for="city in cities"
              :key="city.id"
              :value="city.id"
              :title="city.name"
            >
              {{ city.name }}
            </option>
          </select>
          <span v-if="!$v.client.cityId.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
      </div>
      <div v-if="clientId" class="form-row">
        <div class="form-group col-sm-6">
          <p>Status: <span class="text-danger">*</span></p>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              id="statusInativo"
              value="0"
              v-model="client.status"
            />
            <label class="form-check-label" for="statusInativo">
              Inativo
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              type="radio"
              class="form-check-input"
              id="statusAtivo"
              value="1"
              v-model="client.status"
            />
            <label class="form-check-label" for="statusAtivo"> Ativo </label>
          </div>
        </div>
      </div>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="button"
          class="btn btn-secondary"
          title="Cancelar"
          @click="$emit('closeModal', false)"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          :title="textModal"
          :disabled="$v.$invalid || disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>{{ textModal }}</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'

export default {
  name: 'CreateEditClientModal',

  components: {
    TheMask,
  },

  props: {
    clientId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      client: {
        name: '',
        cpfcnpj: '',
        email: '',
        phone: '',
        stateId: '',
        cityId: '',
      },
      disableButton: false,
      states: null,
      cities: null,
      loadDataEditing: false,
    }
  },

  computed: {
    textModal() {
      return this.clientId ? 'Atualizar' : 'Cadastrar'
    },
  },

  watch: {
    'client.stateId': {
      handler() {
        if (!this.loadDataEditing) {
          this.client.cityId = ''
        }
        this.getCities()
      },
    },
  },

  methods: {
    async getStates() {
      try {
        this.states = await this.$store.dispatch('getStates')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getCities() {
      try {
        this.cities = await this.$store.dispatch(
          'getCities',
          this.client.stateId,
        )
        this.loadDataEditing = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    async getClient() {
      try {
        this.loadDataEditing = true
        this.client = await this.$store.dispatch('getClient', this.clientId)
        this.$bvModal.show('modalCreateEditClient')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async save() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          let response = null
          if (this.clientId) {
            response = await this.$store.dispatch('updateClient', this.client)
          } else {
            response = await this.$store.dispatch('saveClient', this.client)
          }
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
          })
          this.$emit('closeModal', true)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    client: {
      name: {
        required,
        minLength: minLength(8),
      },
      cpfcnpj: {
        required,
        minLength: minLength(14),
        maxLength: maxLength(18),
      },
      phone: {
        required,
        minLength: minLength(14),
        maxLength: maxLength(15),
      },
      email: {
        email,
      },
      stateId: {
        required,
      },
      cityId: {
        required,
      },
    },
  },

  mounted() {
    this.getStates()
    if (this.clientId) {
      this.getClient()
    } else {
      this.$bvModal.show('modalCreateEditClient')
    }
  },
}
</script>
