import { render, staticRenderFns } from "./CreateEditProjectModal.vue?vue&type=template&id=5c544546&"
import script from "./CreateEditProjectModal.vue?vue&type=script&lang=js&"
export * from "./CreateEditProjectModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports