<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <CreateEditClientModal
      v-if="showCreateEditClientModal"
      :clientId="clientIdSelected"
      @closeModal="closeCreateEditClientModal"
    />
    <LoadingData v-if="loadData" />
    <div v-else class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center bg-transparent border-bottom"
      >
        <h5 class="card-title mb-0">Clientes</h5>
        <div v-if="getModuleUserByCode('1.01.002')">
          <button
            type="button"
            class="btn btn-primary"
            title="Novo Cliente"
            @click="createEditClient()"
          >
            Novo Cliente
          </button>
        </div>
      </div>
      <div class="card-body">
        <ListClients :clients="clients" @editClient="createEditClient" />
      </div>
    </div>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'
import CreateEditClientModal from '@/components/backoffice/clients/CreateEditClientModal.vue'
import LoadingData from '@/components/commons/LoadingData.vue'
import ListClients from '@/components/backoffice/clients/ListClients.vue'

export default {
  name: 'Clients',

  components: {
    Layout,
    PageHeader,
    CreateEditClientModal,
    LoadingData,
    ListClients,
  },

  data() {
    return {
      title: 'Clientes',
      items: [
        {
          text: 'BackOffice',
        },
        {
          text: 'Clientes',
          active: true,
        },
      ],
      loadData: true,
      showCreateEditClientModal: false,
      clients: null,
      clientIdSelected: null,
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    async getClients() {
      try {
        this.clients = await this.$store.dispatch('getClients')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    createEditClient(clientId) {
      this.clientIdSelected = clientId || null
      this.showCreateEditClientModal = true
    },

    closeCreateEditClientModal(loadListClients) {
      if (loadListClients) {
        this.getClients()
      }
      this.showCreateEditClientModal = false
    },
  },

  mounted() {
    this.getClients()
  },
}
</script>
