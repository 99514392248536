<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Mostrar
            <b-form-select
              v-model="table.perPage"
              size="sm"
              :options="table.pageOptions"
            />
            registros
          </label>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center">
            Pesquisar:
            <b-form-input
              type="search"
              class="form-control form-control-sm ml-2"
              placeholder="Pesquisar..."
              v-model="table.filter"
            />
          </label>
        </div>
      </div>
    </div>
    <div class="table-responsive mb-0">
      <b-table
        :items="clients"
        :fields="table.fields"
        responsive="sm"
        :per-page="table.perPage"
        :current-page="table.currentPage"
        :sort-by.sync="table.sortBy"
        :sort-desc.sync="table.sortDesc"
        :filter="table.filter"
        :filter-included-fields="table.filterOn"
        @filtered="onFiltered"
      >
        <template #cell(status)="row">
          <span v-html="row.value"></span>
        </template>

        <template #cell(actions)="row">
          <button
            v-if="getModuleUserByCode('1.01.003')"
            type="button"
            class="btn btn-outline-warning rounded-pill"
            title="Editar"
            @click="$emit('editClient', row.item.id)"
          >
            <i class="ri-pencil-line align-middle"></i>
          </button>
          <router-link
            :to="`clients/${row.item.id}`"
            class="btn btn-outline-info rounded-pill ml-2"
            title="Detalhes"
          >
            <i class="ri-file-list-line align-middle"></i>
          </router-link>
        </template>
      </b-table>
    </div>
    <div class="row">
      <div class="col d-inline-flex align-items-center">
        Quantidade de registros: {{ clients.length }}
        <span v-if="table.filter != null && table.filter != ''">
          &nbsp;(Filtrados: {{ table.totalRows }})
        </span>
      </div>
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <b-pagination
              v-model="table.currentPage"
              :total-rows="table.totalRows"
              :per-page="table.perPage"
            />
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { statusTranslate } from '@/utils/strings'

export default {
  name: 'ListClients',

  props: {
    clients: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      table: {
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: 'name',
        sortDesc: false,
        fields: [
          { key: 'name', label: 'Nome', sortable: true },
          { key: 'cpfcnpj', label: 'CNPJ/CPF', sortable: true },
          { key: 'phone', label: 'Telefone', sortable: true },
          { key: 'email', label: 'E-mail', sortable: true },
          {
            key: 'city',
            label: 'Cidade/Estado',
            sortable: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return `${item.city}/${item.uf}`
            },
          },
          {
            key: 'status',
            label: 'Status',
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value) => {
              return statusTranslate(value)
            },
          },
          {
            key: 'actions',
            label: 'Ações',
            thStyle: { width: '130px' },
          },
        ],
      },
    }
  },

  computed: {
    ...mapGetters(['getModuleUserByCode']),
  },

  methods: {
    onFiltered(filteredItems) {
      this.table.totalRows = filteredItems.length
      this.table.currentPage = 1
    },
  },

  mounted() {
    this.table.totalRows = this.clients.length
  },
}
</script>
